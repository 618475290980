import { render, staticRenderFns } from "./ValidateEmailPage.vue?vue&type=template&id=78b8ab2c&"
import script from "./ValidateEmailPage.vue?vue&type=script&lang=js&"
export * from "./ValidateEmailPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports