<script>
export default {
  name: 'ValidateEmailPage',
  data() {
    return {
      status: 'PENDING',
    };
  },
  /* Push user to home if no token in url */
  beforeRouteEnter(to, from, next) {
    if (!('t' in to.query)) {
      next({
        name: 'LoginPage',
        replace: true,
      });
    }
    next();
  },
  mounted() {
    // Send a request to the backend to validate the token
    this.$store.dispatch('user/validateEmail', this.$route.query.t)
      .then(() => {
        // Show a success message and go to login page 5 seconds later
        this.status = 'DONE';
      })
      .catch(() => {
        // Show an error message in this case
        this.status = 'ERROR';
      });
  },
};
</script>
<template>
  <b-container>
    <b-row align-h='center'>
      <b-col v-if="status === 'PENDING'" cols='auto'>
        Please wait, we are dealing with your request...
      </b-col>
      <b-col v-else-if="status === 'DONE'" cols='auto'>
        Done! Your email has been verified. <b-link to='/'>Click here</b-link> to login.
      </b-col>
      <b-col v-else cols='auto'>
        An error occurred, please check you copied the link correctly.<br>
        If the problem persists, contact support.
      </b-col>
    </b-row>
  </b-container>
</template>
